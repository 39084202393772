export const URL = {
  CDN_LIVE: 'https://cdn.vp.mts.ru/live',
  CDN_ZOOM: 'https://cdn.vp.mts.ru/zoom',
  ISSO_AVATAR: 'https://cdn.vp.mts.ru/aang/v1/avatar'
}

const PREFIX = 'api'
const PRE_V1 = 'api/v1'

const PREFIX_V1 = {
  ZOOM: 'zoom/v1',
  AUTH: 'auth/v1',
  LIVE: 'live/v1',
  RECORDS: 'records/v1',
  VIDECAM: 'videcam/v1'
}

export const API_URL = {
  BROADCAST: `/${PREFIX}/broadcasts`,
  FILE: `/${PREFIX}/file`,

  BROADCAST_V1: `/${PRE_V1}/broadcasts`,
  PARTICIPANTS_V1: `/${PRE_V1}/participants`,
  LIVE: `/${PRE_V1}/live`,
  USER: `/${PRE_V1}/admin`,
  FULLNAME: `/${PRE_V1}/user`,
  IMAGE: `/${PREFIX}/images`,
  TOKEN: `/${PRE_V1}/token`,
  STREAM: `/${PRE_V1}/stream`,
  STREAM_CHAT: `/${PRE_V1}/stream/chat`,
  MESSAGES: `/${PRE_V1}/messages`,

  ZOOM: `/${PRE_V1}/zoom`,
  ZOOM_EMAIL: `/${PRE_V1}/zoom/email`,

  ZOOM_V1: `/${PREFIX_V1.ZOOM}`,
  ZOOM_V1_EMAIL: `/${PREFIX_V1.ZOOM}/email`,
  ZOOM_V1_S3: `/${PREFIX_V1.ZOOM}/s3`,

  AUTH_V1_CALLBACK: `/${PREFIX_V1.AUTH}/callback`,
  AUTH_V1_ISSO: `/${PREFIX_V1.AUTH}/isso`,

  LIVE_V1: `/${PREFIX_V1.LIVE}`,
  VIDECAM_V1: `/${PREFIX_V1.VIDECAM}`,
  VIDECAM_V1_ADM: `/${PREFIX_V1.VIDECAM}/adm`,
  VIDECAM_V1_WATCH: `/${PREFIX_V1.VIDECAM}/watch`,

  RECORDS_V1: `/${PREFIX_V1.RECORDS}`,
  RECORDS_V1_DEL: `/${PREFIX_V1.RECORDS}/delete`
}

export const APP_ROUTE = {
  WATCH: 'watch',
  LIVE: 'live',
  BROADCAST: 'broadcast',
  REPORT: 'report',
  STREAM: 'stream',
  STREAM_SETTING: 'stream-setting',
  HELP: 'help',
  AI: 'zoom',
  AUDIO: 'audio',
  LOGS: 'logs',
  RECORD: 'record',
  VIDECAM: 'videcam',
  CONTROL: 'control'
}

export const ROUTE_NAME_BROADCAST = {
  NEW: 'NewBroadcast',
  EDIT: 'EditBroadcast'
}

export const ROUTE_NAME_VIDECAM = {
  NEW: 'NewVidecam',
  EDIT: 'EditVidecam',
  ADMIN: 'AdminVidecam'
}
